:root {
  --button-background: #35cdff;
}

html {
  scroll-behavior: smooth;
}
body {
  height: 100%;
  overflow-x: hidden;
  font-family: "Spartan", sans-serif;
  background-image: url("./images/bg3.png");
  background-size: 100%;
  background-repeat: repeat-y;
}
a:hover {
  /* color: rgba(131, 176, 31, 1); */
  text-decoration: underline;
}

.banner {
  background-size: 150%;
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  min-height: 90vh !important;
}

section {
  min-height: 100vh;
}

section p {
  line-height: 2.5;
}

nav {
  background-color: rgba(255, 255, 255, 0.8);
}

.nav-link {
  letter-spacing: 0.8px;
  font-weight: 500;
  color: #555555 !important;
  font-size: 14px;
}

.nav-link:hover {
  color: black !important;
}

.bg-semiwhite {
  background-color: rgba(255, 255, 255, 0.8);
}

.banner p {
  line-height: 1.9;
  font-size: 16px;
  font-weight: 500;
}

.bg-lightgrey {
  /* background-color: #eee; */
}

.president-profile {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 6px solid rgb(189, 189, 189);
}

.contact-us iframe {
  width: inherit;
  height: 400px;
}

.events li {
  font-weight: 500;
  line-height: 25px;
}

.events p {
  line-height: 1.7;
  margin-top: 10px;
}

footer a {
  color: unset;
}

h5 + p {
  font-size: 14px;
  line-height: 1.6;
}

h4 {
  font-size: 1.2rem;
}

.opacity-0 {
  opacity: 0;
}

footer h4 span {
  border-bottom: 1px solid black;
  padding: 2px;
}

footer ul {
  line-height: 1.8;
}

.despre-noi-tab-item {
  width: calc(100% / 3 - 20px);
}

.tab-details-list {
  line-height: 2;
  /* letter-spacing: 0.7px; */
  padding: 30px 10px;
  border-bottom: 1px solid grey;
  background-color: rgba(255, 255, 255, 0.8);
}
.tab-details-list li {
  margin-bottom: 10px;
}

.despre-noi-tab-item h6 span {
  padding-left: 10px;
}

.video-box {
  width: 90% !important;
  /* padding: 10px; */
  margin: 0 auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  border-radius: 2px;
}

.video-box:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.video-box img {
  width: 100%;
}

.video-box p {
  padding: 10px;
  height: 60px;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
}

.contact-form-container button {
  background-color: #77b801 !important;
  display: block !important;
  margin: 0 auto !important;
}

.contact-form-container input {
  height: 60px;
  font-size: 16px;
}
.contact-form-container textarea {
  height: 200px;
}

.petiti img {
  width: 100%;
}

.petiti button {
  background-color: var(--button-background) !important;
  display: block !important;
  margin: 0 auto !important;
}

.petiti button:hover {
  text-decoration: none;
}

p {
  font-size: 18px;
}
.slider-box {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: white;
  border-radius: 5px;
  font-size: 18px;
  padding: 5rem 80px;
}

.slider-box p:first-child {
  min-height: 80px;
  margin-bottom: 10px;
}

.petiti .slick-arrow {
  background-color: unset !important;
  z-index: 9;
}
.slick-prev:before,
.slick-next:before {
  color: var(--button-background) !important;
  font-size: 40px;
}

.petiti .slick-next {
  /* position: relative; */
  right: 40px;
}
.petiti .slick-prev {
  /* position: relative; */
  left: 25px;
}

.media-container .slick-prev {
  left: -3px;
  /* position: relative; */
  z-index: 9;
  top: 30%;
}

.media-container .slick-next {
  right: 8px;
  z-index: 9;
  top: 30%;
}

.slick-dots button {
  background-color: unset !important;
}

.media-container .slick-slide {
  height: 320px;
}

.articles-container .media-item {
  height: 300px;
  background: white;
  padding: 10px;
  overflow-y: hidden;
  border-radius: 2px;
}

.articles-container .media-item img {
  width: 100%;
  height: 230px;
}

.articles-container .media-item span {
  font-size: 14px;
  line-height: 1px;
  color: black;
  font-weight: bolder;
}

.petiti-image {
  width: 60% !important;
  display: block;
  margin: 0 auto;
}

.petiti .slick-slide > div {
  width: 80%;
  margin: 0 auto;
  /* height: 250px; */
}

.quote {
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
  padding: 0px 5rem;
}

.about-us .nav-tabs .nav-item.show .nav-link,
.about-us .nav-tabs .nav-link.active {
  background: rgba(255, 255, 255, 0.8);
}

.about-us .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: white;
  min-height: 120px;
}
.petiti .slick-slide > div {
  height: fit-content;
}

.lang-control {
  position: absolute;
  right: 10px;
  font-size: 14px;
  bottom: 10px;
}

.lang-control a {
  cursor: pointer;
}

.lang-control .active {
  color: blue;
  font-weight: bold;
}

.bio {
  background-color: white;
  border-radius: 10px;
  padding: 10px 5px;
  text-align: center;
  min-height: 250px;
}
.bio img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
}

.modal-bio-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}

.bio p {
  font-size: 14px;
  font-weight: bold;
}

.bio a {
  font-size: 10px;
  color: blue;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.events-image {
  width: 60%;
  margin: 0 auto;
  display: block;
}

.custom-modal-backdrop {
  text-align: justify;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-modal {
  background: white;
  /* padding: 20px; */
  width: 60%;
  margin: 0 auto;
  position: relative;
  min-width: 300px;
  border-radius: 10px;
  top: 20px;
}

.youtube-pop .custom-modal {
  width: 80vw;
  height: 70vh;
}

.youtube-pop .modal-body{
  height:90%
}

@media (max-width: 1024px) {
  .quote {
    padding: 0px 3rem;
  }

  section {
    min-height: unset;
    margin-top: 20px;
  }

  .petiti .slick-slide > div {
    height: 250px;
  }

  .slider-box {
    height: 100%;
    overflow-y: scroll;
  }
  .media-container .slick-slide {
    height: 230px;
  }

  .media-container .slick-list {
    margin: 0 6rem;
  }
  /* 
  .media-container .slick-next {
    right:35px
  }
  .media-container .slick-prev {
    left:35px
  } */
}

@media (max-width: 565px) {
  body {
    background-image: none;
    background-color: #30c0f0;
  }
  .slider-box {
    padding: 1rem 2rem;
  }

  .custom-modal {
    height: 70vh;
    overflow-y: scroll;
  }

  .slick-next {
    right: 10px;
  }
  .slick-prev {
    left: -10px;
  }

  .video-box {
    width: 90% !important;
  }
  .video-box img {
    width: 100%;
  }

  .media-container .slick-list {
    margin: 0 1rem;
  }
  .media-container .slick-arrow {
    z-index: 9;
  }
  .despre-noi-tab-item h6 span {
    display: none;
  }

  .about-us .nav-tabs .nav-link {
    background-color: transparent;
  }

  .row {
    margin: 0;
  }

  .contact-form-container .petiti button {
    margin-top: 1.5rem !important;
  }
  .petiti-image {
    width: 100% !important;
  }
  .quote {
    padding: 0px 0.5rem;
  }

  .navbar {
    padding-bottom: 20px;
    background-color: white;
  }
}
